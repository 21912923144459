import Vue from "vue";
import VueLuxon from "vue-luxon";
import VueCookies from "vue-cookies";
import VueObserveVisibility from "vue-observe-visibility";
import VueSmoothScroll from "vue2-smooth-scroll";
import App from "./App.vue";
import router from "./router";
import axios from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(axios);

Vue.use(VueLuxon, {
  templates: {
    tz_med: {
      zone: "local",
      format: "med",
    },
    tz_date_med: {
      zone: "local",
      format: "date_med",
    },
    tz_date_full: {
      zone: "local",
      format: "date_full",
    },
  },
  input: {
    zone: "Australia/Melbourne",
    format: "iso",
  },
});

Vue.use(VueCookies, { expires: "1d" });
Vue.use(VueObserveVisibility);
Vue.use(VueSmoothScroll, {
  updateHistory: false,
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
