<template>
  <v-card outlined class="mb-5 rounded-xl">
    <v-toolbar dark flat color="warning">
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-alert</v-icon>
        Action Required
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      You have
      <span v-if="openApprovals.length === 1"> an approval </span>
      <span v-if="openApprovals.length > 1">
        {{ openApprovals.length }} approvals
      </span>
      requiring attention, see below for more information.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ActionRequired",
  computed: {
    ...mapGetters(["collection", "openApprovals"]),
  },
};
</script>
