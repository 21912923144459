<template>
  <v-dialog v-model="viewFileModal">
    <v-card v-if="value" class="rounded-xl" min-height="80vh">
      <v-toolbar dark flat color="primary">
        <v-btn icon @click="viewFileModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="filePath"
          outlined
          class="rounded-xl"
          @click="downloadFile()"
        >
          <v-icon class="mr-2">mdi-download</v-icon>
          Download
        </v-btn>
      </v-toolbar>
      <v-card flat class="overflow-y-auto overflow-x-hidden" max-height="80vh">
        <v-row v-if="!filePath">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              size="128"
              color="primary"
              class="my-5"
            >
              Loading File
            </v-progress-circular>
          </v-col>
        </v-row>
        <v-container fluid v-if="filePath">
          <v-img
            contain
            v-if="fileType === 'image'"
            :src="`${filePath}`"
            height="80vh"
          />
          <div v-if="fileType === 'html'" class="iframe-container">
            <iframe v-if="!smallScreen" :src="`${filePath}`" />
          </div>
          <ModelViewer
            class="ma-0 pa-0"
            background-color="#1E1E1E"
            :file-path="filePath"
            v-if="fileType === 'model' && canvasReady"
            :width="canvasWidth"
            :height="canvasHeight"
          />
          <v-row v-if="cannotView">
            <v-col class="text-center my-5">
              <p>We cannot preview this type of file...</p>
              <p class="mb-5">Please download it to your device</p>
              <v-btn outlined class="mt-5 rounded-xl" @click="downloadFile()">
                <v-icon class="mr-2">mdi-download</v-icon>
                Download
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="htmlSmallScreenView">
            <v-col class="text-center my-5">
              <p class="mb-5">
                Please use the button below to view your design.
              </p>
              <v-btn outlined class="mt-5 rounded-xl" @click="downloadFile()">
                <v-icon class="mr-2">mdi-open-in-new</v-icon>
                Open File
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import ModelViewer from "@/components/shared/ModelViewer";

export default {
  name: "FileViewer",
  props: ["value", "type"],
  components: {
    ModelViewer,
  },
  data() {
    return {
      filePath: null,
      fileName: null,
      cannotView: false,
      htmlSmallScreenView: false,
      canvasReady: false,
      canvasWidth: null,
      canvasHeight: null,
      unregisterRouterGuard: null,
    };
  },
  watch: {
    value(file) {
      if (!file) {
        return;
      }
      this.fetchFileURI();
    },
    viewFileModal(value) {
      if (value) {
        this.unregisterRouterGuard = this.$router.beforeEach(
          (to, from, next) => {
            this.viewFileModal = false;
            next(false);
          }
        );
      } else {
        if (this.unregisterRouterGuard) {
          this.unregisterRouterGuard();
        }
      }
    },
  },
  computed: {
    fileType() {
      if (!this.fileName) {
        return;
      }
      const fileExt = this.fileName.toLowerCase().split(".").pop();
      switch (fileExt) {
        case "html":
          return "html";
        case "jpg":
        case "jpeg":
        case "png":
          return "image";
        case "ply":
        case "stl":
          return "model";
        default:
          return "download";
      }
    },
    viewFileModal: {
      get() {
        if (this.value) {
          return true;
        }
        return false;
      },
      set(value) {
        this.$emit("input", value);
        this.filePath = null;
        this.fileName = null;
        this.cannotView = false;
        this.htmlSmallScreenView = false;
      },
    },
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
  },
  methods: {
    async fetchFileURI() {
      this.$axios.get(`/file/${this.type}/${this.value}/`).then((response) => {
        this.filePath = response.data.url;
        this.fileName = response.data.filename;
        if (this.fileType === "download") {
          this.cannotView = true;
        } else if (this.fileType === "html" && this.smallScreen) {
          this.htmlSmallScreenView = true;
        }
      });
    },
    downloadFile() {
      window.open(this.filePath, "_blank");
      this.$emit("input", false);
    },
    setCanvasHeight() {
      this.canvasHeight = window.innerHeight - (this.smallScreen ? 90 : 100);
    },
    setCanvasWidth() {
      this.canvasWidth = window.innerWidth - (this.smallScreen ? 25 : 30);
    },
  },
  mounted() {
    setTimeout(() => {
      this.setCanvasHeight();
      this.setCanvasWidth();
      this.canvasReady = true;
    }, 100);
  },
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 80%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
