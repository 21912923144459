<template>
  <v-card outlined width="100%" class="rounded-xl" id="active">
    <v-toolbar dark flat color="primary">
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-factory</v-icon>
        Cases in Production
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="extended = !extended">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
    <CaseSearch v-model="caseFilter" :extended="extended" color="primary" />
    <v-card-text>
      <v-card class="overflow-y-auto" flat max-height="600">
        <div
          class="ma-0 py-1"
          v-for="(kase, index) in filteredActiveCases"
          :key="index"
        >
          <v-lazy>
            <CaseCard :kase="kase" />
          </v-lazy>
        </div>
      </v-card>
      <div v-if="filteredActiveCases.length === 0" class="text-center my-5">
        <v-icon>mdi-sleep</v-icon>
        <br />
        No cases found...
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CaseCard from "@/components/shared/CaseCard";
import CaseSearch from "@/components/shared/CaseSearch";

export default {
  name: "ActiveCases",
  components: {
    CaseCard,
    CaseSearch,
  },
  data() {
    return {
      caseFilter: "",
      extended: false,
    };
  },
  computed: {
    ...mapGetters(["activeCases"]),
    filteredActiveCases() {
      if (!this.caseFilter) {
        return this.activeCases;
      }
      return this.activeCases.filter((activeCase) => {
        return (
          `${activeCase.collection_id}`.includes(this.caseFilter) ||
          activeCase.patient_reference
            .toLowerCase()
            .includes(this.caseFilter.toLowerCase())
        );
      });
    },
  },
};
</script>
