<template>
  <v-row
    class="py-2"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 2000,
    }"
  >
    <v-col sm="12" :align="align">
      <v-card elevation="0">
        <v-card-text class="py-0 my-0">
          <v-chip
            :id="chatHighlightUID === message.uid ? 'messagePointer' : ''"
            :color="bubbleColor"
            :class="chipClass"
            class="pa-2 text-left"
            style="height: auto"
          >
            <span style="white-space: pre-wrap">
              <span>{{ message.note }}</span>
            </span>
          </v-chip>
        </v-card-text>
        <v-card-text v-if="message.attachments.length > 0">
          <MessageAttachments :message="message" />
        </v-card-text>
      </v-card>
      <div class="mx-5">
        <small>
          <span v-if="message.author_reference">
            <v-icon small> mdi-account </v-icon>
            {{ message.author_reference }}
            -
          </span>
          {{ message.created_at | luxon({ output: { format: "med" } }) }}
        </small>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageAttachments from "@/components/shared/MessageAttachments";

export default {
  name: "CaseMessage",
  props: ["message"],
  components: {
    MessageAttachments,
  },
  computed: {
    ...mapGetters(["chatHighlightUID"]),
    align() {
      if (this.message.direction === "INBOUND") {
        return "right";
      }
      return "left";
    },
    bubbleColor() {
      if (this.message.direction === "OUTBOUND") {
        return "primary";
      }
      return "null";
    },
    chipClass() {
      if (this.message.uid === this.chatHighlightUID) {
        return "pa-2 text-left pulsing";
      }
      return "pa-2 text-left";
    },
  },
  methods: {
    ...mapActions(["setMessageSeen"]),
    async visibilityChanged(isVisible) {
      if (!isVisible || this.message.message_seen) {
        return;
      }
      this.$axios.patch(`/message/${this.message.uid}/`, {
        action: "read",
      });
      this.setMessageSeen(this.message.uid);
    },
  },
};
</script>
