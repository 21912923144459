<template>
  <v-expansion-panels accordion class="rounded-xl" flat>
    <v-expansion-panel
      v-for="(kase, index) in aggregateCases"
      :key="index"
      style="border: thin solid rgba(0, 0, 0, 0.12)"
    >
      <v-expansion-panel-header style="border-radius: inherit">
        <v-row>
          <v-col cols="12">
            {{ kase.job_type }}
          </v-col>
          <v-col cols="12" class="text-right">
            <Status :kase="kase" class="mr-2" />
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <ExpandedDetail :kase="kase" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from "vuex";
import Status from "./StatusChip";
import ExpandedDetail from "./ExpandedDetail";

export default {
  name: "CaseComponents",
  props: ["cases"],
  components: {
    Status,
    ExpandedDetail,
  },
  computed: {
    ...mapGetters(["collection"]),
    productClasses() {
      return [...new Set(this.cases.map((kase) => kase.product_class))];
    },
    aggregateCases() {
      const cases = [];
      this.productClasses.forEach((productClass) => {
        if (productClass === "Other") {
          cases.push(
            ...this.cases.filter((kase) => kase.product_class === productClass)
          );
          return;
        }
        const groupedCases = [...this.cases]
          .filter((kase) => kase.product_class === productClass)
          .sort((a, b) => {
            const aApprovalCount = this.collection.approvals.filter(
              (approval) => {
                return (
                  approval.case_uid === a.uid &&
                  !approval.approved_at &&
                  !approval.cancelled_at
                );
              }
            ).length;
            const bApprovalCount = this.collection.approvals.filter(
              (approval) => {
                return (
                  approval.case_uid === b.uid &&
                  !approval.approved_at &&
                  !approval.cancelled_at
                );
              }
            ).length;
            if (aApprovalCount === 0 && bApprovalCount === 0) {
              return b.customer_due.localeCompare(a.customer_due);
            }
            return bApprovalCount - aApprovalCount;
          });
        cases.push({
          ...groupedCases[0],
          grouped_case_uids: groupedCases
            .filter((kase) => kase.uid !== groupedCases[0].uid)
            .map((kase) => kase.uid),
          job_type: productClass,
        });
      });
      return cases.sort((a, b) => {
        if (
          a.job_type === "Final Prosthesis" &&
          b.job_type !== "Final Prosthesis"
        ) {
          return 1;
        }
        if (
          a.job_type !== "Final Prosthesis" &&
          b.job_type === "Final Prosthesis"
        ) {
          return -1;
        }
        return 0;
      });
    },
  },
};
</script>

<style scoped>
.v-expansion-panel {
  -webkit-transform: translate3d(0, 0, 0);
}
</style>
