<template>
  <v-dialog v-model="implantsModal" max-width="800" content-class="rounded-xl">
    <v-card v-if="value" class="rounded-xl">
      <v-toolbar dark flat color="primary">
        <v-btn icon @click="implantsModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span v-if="dentate === 'UPPER'">Upper</span>
          <span v-if="dentate === 'LOWER'">Lower</span>
          <span v-if="dentate === 'BOTH'">Upper &amp; Lower</span>
          <span v-if="dentate === 'NONE'">Other</span>
          Implants
        </v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-card
          flat
          class="rounded-xl overflow-y-auto overflow-x-hidden"
          max-height="80vh"
        >
          <v-card
            v-for="(implant, index) in implants"
            :key="index"
            outlined
            class="mb-3 rounded-xl"
          >
            <v-card-text>
              <v-row align="center">
                <v-col cols="2" class="text-center">
                  <v-icon>mdi-tooth</v-icon>
                  {{ getToothNumber(implant.tooth) }}
                </v-col>
                <v-col cols="10">
                  {{ implant.platform }}
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-chip
                    small
                    :color="implant.engaging ? 'success' : ''"
                    class="mr-2"
                  >
                    <v-icon small v-if="implant.engaging" class="mr-2"
                      >mdi-check</v-icon
                    >
                    <v-icon small v-if="!implant.engaging" class="mr-2"
                      >mdi-close</v-icon
                    >
                    <span v-if="!implant.engaging">Non-</span>
                    Engaging
                  </v-chip>
                  <v-chip small :color="implant.biaxial ? 'success' : ''">
                    <v-icon small v-if="implant.biaxial" class="mr-2"
                      >mdi-check</v-icon
                    >
                    <v-icon small v-if="!implant.biaxial" class="mr-2"
                      >mdi-close</v-icon
                    >
                    <span v-if="!implant.biaxial">Non-</span>
                    Bi-Axial
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import internationalToothNumbers from "@/components/shared/international_tooth_numbers";

export default {
  name: "ImplantsViewer",
  props: ["value", "dentate"],
  data() {
    return {
      unregisterRouterGuard: null,
    };
  },
  watch: {
    implantsModal(value) {
      if (value) {
        this.unregisterRouterGuard = this.$router.beforeEach(
          (to, from, next) => {
            this.implantsModal = false;
            next(false);
          }
        );
      } else {
        if (this.unregisterRouterGuard) {
          this.unregisterRouterGuard();
        }
      }
    },
  },
  computed: {
    ...mapGetters(["internationalToothConvention", "casesByDentate"]),
    implantsModal: {
      get() {
        if (this.value) {
          return true;
        }
        return false;
      },
      set(value) {
        this.$emit("input", value);
        this.filePath = null;
        this.cannotView = false;
      },
    },
    implants() {
      const cases = [];
      if (this.dentate === "UPPER") {
        cases.push(...this.casesByDentate.upper);
      }
      if (this.dentate === "LOWER") {
        cases.push(...this.casesByDentate.lower);
      }
      if (this.dentate === "BOTH") {
        cases.push(...this.casesByDentate.both);
      }
      if (this.dentate === "NONE") {
        cases.push(...this.casesByDentate.none);
      }
      const uniqueImplants = [];
      cases
        .flatMap((kase) => kase.implants)
        .forEach((implant) => {
          if (
            uniqueImplants.filter((uniqueImplant) => {
              return (
                implant.tooth === uniqueImplant.tooth &&
                implant.platform === uniqueImplant.platform &&
                implant.engaging === uniqueImplant.engaging &&
                implant.biaxial === uniqueImplant.biaxial
              );
            }).length === 0
          ) {
            uniqueImplants.push(implant);
          }
        });
      return uniqueImplants
        .filter((implant) => !!implant.platform)
        .sort((a, b) => a.tooth - b.tooth);
    },
  },
  methods: {
    getToothNumber(toothNumber) {
      return internationalToothNumbers[toothNumber][
        this.internationalToothConvention
      ].padStart(2, "0");
    },
  },
};
</script>
