<template>
  <v-card outlined width="100%" class="rounded-xl" v-if="currentClient">
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-hospital-box</v-icon>
        {{ currentClient.name }}
      </v-toolbar-title>
    </v-toolbar>
    <CaseSearch v-model="caseSearch" :extended="true" color="primary" />
    <v-card-text v-if="caseSearch && caseSearch.length > 0">
      <h3>
        <v-icon class="mr-2">mdi-magnify</v-icon>
        Search Results
      </h3>
      <div
        class="ma-0 py-1"
        v-for="(kase, index) in searchResults"
        :key="index"
      >
        <v-lazy>
          <CaseCard :kase="kase" />
        </v-lazy>
      </div>
      <div class="ma-0 py-5 text-center" v-if="searchResults.length === 0">
        No Cases Found
      </div>
      <hr />
    </v-card-text>
    <v-card-text>
      <v-list rounded>
        <v-list-item
          class="my-5"
          :to="{ name: 'targetHomePage', params: { target: 'approval' } }"
        >
          <v-list-item-icon>
            <v-icon large>mdi-account-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Requiring Action </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar
            color="warning"
            :class="approvalRequiredCases.length > 0 ? 'pulsing' : ''"
          >
            <span class="mx-auto white--text">{{
              approvalRequiredCases.length
            }}</span>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item
          class="my-5"
          :to="{ name: 'targetHomePage', params: { target: 'active' } }"
        >
          <v-list-item-icon>
            <v-icon large>mdi-factory</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> In Production </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar color="primary">
            <span class="mx-auto white--text">{{ activeCases.length }}</span>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item
          class="my-5"
          :to="{ name: 'targetHomePage', params: { target: 'archived' } }"
        >
          <v-list-item-icon>
            <v-icon large>mdi-check-all</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Completed </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar color="secondary">
            <span class="mx-auto white--text">{{ archivedCases.length }}</span>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CaseSearch from "@/components/shared/CaseSearch";
import CaseCard from "@/components/shared/CaseCard";

export default {
  name: "PracticeOverview",
  components: {
    CaseSearch,
    CaseCard,
  },
  data() {
    return {
      caseSearch: "",
      addMobileModal: false,
      addMobileQuestion: false,
      newMobile: "",
      phoneResults: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentClient",
      "allCases",
      "activeCases",
      "archivedCases",
      "approvalRequiredCases",
    ]),
    searchResults() {
      if (this.caseSearch.length === 0) {
        return [];
      }
      return this.allCases
        .filter((kase) => {
          return (
            `${kase.collection_id}`.includes(this.caseSearch) ||
            kase.patient_reference
              .toLowerCase()
              .includes(this.caseSearch.toLowerCase())
          );
        })
        .slice(0, 5);
    },
  },
};
</script>

<style>
.pulsing {
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 140, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
  }
}
</style>
