<template>
  <v-navigation-drawer v-model="drawer" width="350" temporary app>
    <v-list dense rounded>
      <v-list-item class="primary--text" v-if="currentClient">
        <v-list-item-icon>
          <v-icon color="primary">mdi-hospital-box</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ currentClient.name }}
        </v-list-item-title>
      </v-list-item>
      <v-divider class="my-2" v-if="clients.length > 1" />
      <v-list-item @click="changePractice()" v-if="clients.length > 1">
        <v-list-item-icon>
          <v-icon>mdi-swap-horizontal</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Change Practice </v-list-item-title>
      </v-list-item>
      <v-divider class="my-2" />
      <v-list-item
        :input-value="$route.name === 'homePage'"
        @click="navigateHome()"
      >
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Overview </v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'orderForm' }">
        <v-list-item-icon>
          <v-icon>mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title> New Patient Order </v-list-item-title>
      </v-list-item>
      <v-divider class="my-2" v-if="clients.length > 1" />
      <v-list-item
        :to="{ name: 'targetHomePage', params: { target: 'incomplete' } }"
      >
        <v-list-item-icon>
          <v-badge overlap color="light-green">
            <template v-slot:badge>
              {{ incompleteSubmissions.length }}
            </template>
            <v-icon>mdi-pencil</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title> Incomplete Orders </v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: 'targetHomePage', params: { target: 'approval' } }"
      >
        <v-list-item-icon>
          <v-badge overlap color="warning">
            <template v-slot:badge>
              {{ approvalRequiredCases.length }}
            </template>
            <v-icon>mdi-account-check</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title> Requiring Action </v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: 'targetHomePage', params: { target: 'active' } }"
      >
        <v-list-item-icon>
          <v-badge overlap color="primary">
            <template v-slot:badge>
              {{ activeCases.length }}
            </template>
            <v-icon>mdi-factory</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title> In Production </v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: 'targetHomePage', params: { target: 'archived' } }"
      >
        <v-list-item-icon>
          <v-badge overlap color="secondary">
            <template v-slot:badge>
              {{ archivedCases.length }}
            </template>
            <v-icon>mdi-check-all</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title> Completed </v-list-item-title>
      </v-list-item>
      <v-divider class="my-2" />
      <v-list-item :to="{ name: 'settings' }">
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Settings </v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'about' }">
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-title> About </v-list-item-title>
      </v-list-item>
      <v-divider class="my-2" v-if="clients.length > 1" />
      <v-list-item :to="{ name: 'logOut' }" class="error--text">
        <v-list-item-icon>
          <v-icon color="error">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Logout </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavigationDrawer",
  props: ["value"],
  computed: {
    ...mapGetters([
      "clients",
      "currentClient",
      "activeCases",
      "archivedCases",
      "approvalRequiredCases",
      "incompleteSubmissions",
    ]),
    ...mapGetters(["termsAccepted"]),
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    changePractice() {
      this.$router.push({ name: "practiceSelector" });
    },
    newOrder() {
      this.$router.push({ name: "orderForm" });
    },
    navigateHome() {
      this.$router.push({ name: "homePage" });
    },
  },
};
</script>
