<template>
  <v-card outlined class="rounded-xl">
    <v-toolbar dark flat color="primary">
      <v-toolbar-title>
        <span v-if="dentate === 'UPPER'">
          <v-icon class="mr-2">mdi-arrow-up</v-icon>
          Upper Dentate
        </span>
        <span v-if="dentate === 'LOWER'">
          <v-icon class="mr-2">mdi-arrow-down</v-icon>
          Lower Dentate
        </span>
        <span v-if="dentate === 'BOTH'">
          <v-icon class="mr-2">mdi-arrow-up-down</v-icon>
          Upper &amp; Lower Dentates
        </span>
        <span v-if="dentate === 'NONE'">
          <v-icon class="mr-2">mdi-circle-outline</v-icon>
          Other
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="text-center" v-if="dentateTeeth.length > 0">
          <ToothPicker
            :convention="internationalToothConvention"
            :selected="dentateTeeth"
            :custom-colors="customColors"
            style="max-height: 256px"
          />
          <p>
            <v-btn
              small
              outlined
              color="primary"
              class="rounded-xl"
              v-if="implants.length > 0"
              @click="showImplants = true"
            >
              <v-icon small class="mr-2">mdi-tooth</v-icon>
              Implant Details
            </v-btn>
          </p>
          <ImplantsViewer v-model="showImplants" :dentate="dentate" />
        </v-col>
        <v-col cols="12">
          <CaseComponents :cases="dentateCases" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ToothPicker from "@/components/shared/ToothPicker";
import ImplantsViewer from "./CaseDetail/ImplantsViewer";
import CaseComponents from "./CaseDetail/CaseComponents";

export default {
  name: "CaseDetail",
  props: ["dentate"],
  components: {
    ToothPicker,
    CaseComponents,
    ImplantsViewer,
  },
  data() {
    return {
      showImplants: false,
    };
  },
  computed: {
    ...mapGetters([
      "internationalToothConvention",
      "casesByDentate",
      "implantsByDentate",
      "ponticsByDentate",
    ]),
    dentateTeeth() {
      return [...this.implants, ...this.pontics];
    },
    implants() {
      if (!this.implantsByDentate) {
        return [];
      }
      const teeth = [];
      if (this.dentate === "UPPER") {
        teeth.push(...this.implantsByDentate.upper);
      }
      if (this.dentate === "LOWER") {
        teeth.push(...this.implantsByDentate.lower);
      }
      if (this.dentate === "BOTH") {
        teeth.push(...this.implantsByDentate.both);
      }
      if (this.dentate === "NONE") {
        teeth.push(...this.implantsByDentate.none);
      }
      return teeth;
    },
    pontics() {
      if (!this.ponticsByDentate) {
        return [];
      }
      const teeth = [];
      if (this.dentate === "UPPER") {
        teeth.push(...this.ponticsByDentate.upper);
      }
      if (this.dentate === "LOWER") {
        teeth.push(...this.ponticsByDentate.lower);
      }
      if (this.dentate === "BOTH") {
        teeth.push(...this.ponticsByDentate.both);
      }
      if (this.dentate === "NONE") {
        teeth.push(...this.ponticsByDentate.none);
      }
      return teeth;
    },
    customColors() {
      if (this.pontics.length === 0) {
        return {};
      }
      const customColors = {};
      this.pontics.forEach((tooth) => {
        if (!this.implants.includes(tooth)) {
          customColors[tooth] = { start: "#FFCC80", end: "#FF9800" };
        }
      });
      return customColors;
    },
    dentateCases() {
      if (!this.casesByDentate) {
        return [];
      }
      if (this.dentate === "UPPER") {
        return this.casesByDentate.upper;
      }
      if (this.dentate === "LOWER") {
        return this.casesByDentate.lower;
      }
      if (this.dentate === "BOTH") {
        return this.casesByDentate.both;
      }
      return this.casesByDentate.none;
    },
  },
};
</script>
