import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    reloadTrigger: false,
    show: false,
    clients: [],
    currentClientUID: null,
    productTree: [],
    internationalToothConvention: "fdi",
    activeCases: [],
    archivedCases: [],
    approvalRequiredCases: [],
    incompleteSubmissions: [],
    collection: null,
    chatHighlightUID: null,
  },
  getters: {
    accessToken: (state) => state.accessToken,
    isLoggedIn: (state) => !!state.accessToken,
    reloadTrigger: (state) => state.reloadTrigger,
    show: (state) => state.show,
    clients: (state) => state.clients,
    currentClient: (state) => {
      return state.clients.find(
        (client) => client.uid === state.currentClientUID
      );
    },
    targetCountry: (_, getters) => {
      if (getters.currentClient) {
        return getters.currentClient.country_code;
      }
    },
    productTree: (state) => state.productTree,
    internationalToothConvention: (state) => state.internationalToothConvention,
    activeCases: (state) => state.activeCases,
    archivedCases: (state) => state.archivedCases,
    approvalRequiredCases: (state) => state.approvalRequiredCases,
    incompleteSubmissions: (state) => state.incompleteSubmissions,
    collection: (state) => state.collection,
    chatHighlightUID: (state) => state.chatHighlightUID,
    allCases: (state) => {
      return [
        ...state.activeCases,
        ...state.approvalRequiredCases,
        ...state.archivedCases,
      ];
    },
    openApprovals: (state) => {
      return state.collection.approvals.filter((approval) => {
        return !approval.approved_at && !approval.cancelled_at;
      });
    },
    dentates: (_, getters) => {
      const dentates = [];
      if (getters.casesByDentate.upper.length > 0) {
        dentates.push("UPPER");
      }
      if (getters.casesByDentate.lower.length > 0) {
        dentates.push("LOWER");
      }
      if (getters.casesByDentate.both.length > 0) {
        dentates.push("BOTH");
      }
      if (getters.casesByDentate.none.length > 0) {
        dentates.push("NONE");
      }
      return dentates;
    },
    casesByDentate: (state) => {
      const sortedCases = {
        upper: [],
        lower: [],
        both: [],
        none: [],
      };
      if (!state.collection) {
        return sortedCases;
      }
      if (!state.collection.cases) {
        return sortedCases;
      }
      if (state.collection.cases.length === 0) {
        return sortedCases;
      }
      state.collection.cases.forEach((kase) => {
        if (kase.dentate) {
          if (kase.dentate === "BOTH") {
            sortedCases.both.push(kase);
            return;
          }
          if (kase.dentate === "NONE") {
            sortedCases.none.push(kase);
            return;
          }
          if (
            kase.implants.filter((implant) => implant.tooth > 30).length > 0 &&
            kase.implants.filter((implant) => implant.tooth < 30).length > 0
          ) {
            sortedCases.both.push(kase);
            return;
          }
          if (kase.dentate === "UPPER") {
            if (
              kase.implants.filter((implant) => implant.tooth > 30).length > 0
            ) {
              sortedCases.both.push(kase);
              return;
            }
            sortedCases.upper.push(kase);
            return;
          }
          if (
            kase.implants.filter((implant) => implant.tooth < 30).length > 0
          ) {
            sortedCases.both.push(kase);
            return;
          }
          sortedCases.lower.push(kase);
          return;
        }
        if (kase.implants.length === 0) {
          sortedCases.none.push(kase);
          return;
        }
        if (
          kase.implants.filter((implant) => implant.tooth > 30).length > 0 &&
          kase.implants.filter((implant) => implant.tooth < 30).length > 0
        ) {
          sortedCases.both.push(kase);
          return;
        }
        if (kase.implants.filter((implant) => implant.tooth > 30).length > 0) {
          sortedCases.lower.push(kase);
          return;
        }
        sortedCases.upper.push(kase);
      });
      return sortedCases;
    },
    implantsByDentate: (_, getters) => {
      const sortedImplants = {
        upper: [],
        lower: [],
        both: [],
        none: [],
      };
      getters.casesByDentate.upper.forEach((kase) => {
        kase.implants
          .filter((implant) => !!implant.platform)
          .map((implant) => implant.tooth)
          .forEach((tooth) => {
            if (tooth < 30 && !sortedImplants.upper.includes(tooth)) {
              sortedImplants.upper.push(tooth);
            }
          });
      });
      getters.casesByDentate.lower.forEach((kase) => {
        kase.implants
          .filter((implant) => !!implant.platform)
          .map((implant) => implant.tooth)
          .forEach((tooth) => {
            if (tooth > 30 && !sortedImplants.lower.includes(tooth)) {
              sortedImplants.lower.push(tooth);
            }
          });
      });
      getters.casesByDentate.both.forEach((kase) => {
        kase.implants
          .filter((implant) => !!implant.platform)
          .map((implant) => implant.tooth)
          .forEach((tooth) => {
            if (!sortedImplants.both.includes(tooth)) {
              sortedImplants.both.push(tooth);
            }
          });
      });
      getters.casesByDentate.none.forEach((kase) => {
        kase.implants
          .filter((implant) => !!implant.platform)
          .map((implant) => implant.tooth)
          .forEach((tooth) => {
            if (!sortedImplants.none.includes(tooth)) {
              sortedImplants.none.push(tooth);
            }
          });
      });
      return sortedImplants;
    },
    ponticsByDentate: (_, getters) => {
      const sortedPontics = {
        upper: [],
        lower: [],
        both: [],
        none: [],
      };
      getters.casesByDentate.upper.forEach((kase) => {
        kase.implants
          .filter((implant) => !implant.platform)
          .map((implant) => implant.tooth)
          .forEach((tooth) => {
            if (tooth < 30 && !sortedPontics.upper.includes(tooth)) {
              sortedPontics.upper.push(tooth);
            }
          });
      });
      getters.casesByDentate.lower.forEach((kase) => {
        kase.implants
          .filter((implant) => !implant.platform)
          .map((implant) => implant.tooth)
          .forEach((tooth) => {
            if (tooth > 30 && !sortedPontics.lower.includes(tooth)) {
              sortedPontics.lower.push(tooth);
            }
          });
      });
      getters.casesByDentate.both.forEach((kase) => {
        kase.implants
          .filter((implant) => !implant.platform)
          .map((implant) => implant.tooth)
          .forEach((tooth) => {
            if (!sortedPontics.both.includes(tooth)) {
              sortedPontics.both.push(tooth);
            }
          });
      });
      getters.casesByDentate.none.forEach((kase) => {
        kase.implants
          .filter((implant) => !implant.platform)
          .map((implant) => implant.tooth)
          .forEach((tooth) => {
            if (!sortedPontics.none.includes(tooth)) {
              sortedPontics.none.push(tooth);
            }
          });
      });
      return sortedPontics;
    },
  },
  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    SET_RELOAD_TRIGGER(state, payload) {
      state.reloadTrigger = payload;
    },
    SET_SHOW(state, show) {
      state.show = show;
    },
    SET_CLIENTS(state, clients) {
      if (clients.length === 1) {
        state.currentClientUID = clients[0].uid;
      }
      state.clients = clients;
    },
    SET_CURRENT_CLIENT(state, client) {
      if (!client) {
        return (state.currentClientUID = null);
      }
      state.currentClientUID = client.uid;
    },
    SET_PRODUCT_TREE(state, tree) {
      state.productTree = tree;
    },
    SET_INTERNATIONAL_TOOTH_CONVENTION(state, convention) {
      state.internationalToothConvention = convention;
    },
    SET_ACTIVE_CASES(state, activeCases) {
      state.activeCases = activeCases;
    },
    SET_ARCHIVED_CASES(state, archivedCases) {
      state.archivedCases = archivedCases;
    },
    SET_APPROVAL_REQUIRED_CASES(state, approvalRequiredCases) {
      state.approvalRequiredCases = approvalRequiredCases;
    },
    SET_INCOMPLETE_SUBMISSIONS(state, incompleteSubmissions) {
      state.incompleteSubmissions = incompleteSubmissions;
    },
    SET_COLLECTION(state, collection) {
      state.collection = collection;
    },
    SET_CHAT_HIGHLIGHT_UID(state, payload) {
      state.chatHighlightUID = payload;
    },
    SET_MESSAGE_SEEN(state, uid) {
      if (!state.collection) {
        return;
      }
      const message = state.collection.messages.find(
        (message) => message.uid === uid
      );
      if (!message) {
        return;
      }
      message.message_seen = true;
    },
  },
  actions: {
    setAccessToken({ commit }, token) {
      commit("SET_ACCESS_TOKEN", token);
    },
    triggerReload({ commit }) {
      commit("SET_RELOAD_TRIGGER", true);
    },
    reloadComplete({ commit }) {
      commit("SET_RELOAD_TRIGGER", false);
    },
    showCaseNavigation({ commit }) {
      commit("SET_SHOW", true);
    },
    hideCaseNavigation({ commit }) {
      commit("SET_SHOW", false);
    },
    setClients({ commit }, clients) {
      commit("SET_CLIENTS", clients);
    },
    setCurrentClient({ commit }, client) {
      commit("SET_CURRENT_CLIENT", client);
    },
    setProductTree({ commit }, tree) {
      commit("SET_PRODUCT_TREE", tree);
    },
    setInternationalToothConvention({ commit }, convention) {
      commit("SET_INTERNATIONAL_TOOTH_CONVENTION", convention);
    },
    setActiveCases({ commit }, activeCases) {
      commit("SET_ACTIVE_CASES", activeCases);
    },
    setArchivedCases({ commit }, archivedCases) {
      commit("SET_ARCHIVED_CASES", archivedCases);
    },
    setApprovalRequiredCases({ commit }, approvalRequiredCases) {
      commit("SET_APPROVAL_REQUIRED_CASES", approvalRequiredCases);
    },
    setIncompleteSubmissions({ commit }, incompleteSubmissions) {
      commit("SET_INCOMPLETE_SUBMISSIONS", incompleteSubmissions);
    },
    setCollection({ commit }, collection) {
      commit("SET_COLLECTION", collection);
    },
    setChatHighlightUID({ commit }, uid) {
      commit("SET_CHAT_HIGHLIGHT_UID", uid);
    },
    setMessageSeen({ commit }, messageUID) {
      commit("SET_MESSAGE_SEEN", messageUID);
    },
  },
  modules: {},
});
