<template>
  <v-expand-transition>
    <v-card flat :color="color" tile class="rounded-b-xl" v-show="extended">
      <v-card-text class="pa-2">
        <v-text-field
          v-model="keyword"
          dark
          dense
          outlined
          hide-details
          clearable
          rounded
          filled
          placeholder="Search"
        />
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>
export default {
  name: "CaseSearch",
  props: ["value", "extended", "color"],
  computed: {
    keyword: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
