<template>
  <v-progress-circular
    width="8"
    :size="sizes[0]"
    :rotate="rotates[0]"
    :color="colors[0]"
    indeterminate
  >
    <v-progress-circular
      width="8"
      :size="sizes[1]"
      :rotate="rotates[1]"
      :color="colors[1]"
      indeterminate
    >
      <v-progress-circular
        width="8"
        :size="sizes[2]"
        :rotate="rotates[2]"
        :color="colors[2]"
        indeterminate
      >
        <v-progress-circular
          width="8"
          :size="sizes[3]"
          :rotate="rotates[3]"
          :color="colors[3]"
          indeterminate
        >
          <v-progress-circular
            width="8"
            :size="sizes[4]"
            :rotate="rotates[4]"
            :color="colors[4]"
            indeterminate
          >
            <v-img
              contain
              width="100"
              :src="require('@/assets/osteon-logo.png')"
            />
          </v-progress-circular>
        </v-progress-circular>
      </v-progress-circular>
    </v-progress-circular>
  </v-progress-circular>
</template>

<script>
export default {
  name: "RGBSpinner",
  data() {
    return {
      number: 5,
      sizes: ["280", "260", "240", "220", "200"],
      rotates: ["225", "180", "135", "90", "45"],
      colors: ["primary", "primary", "primary", "primary", "primary"],
    };
  },
};
</script>
