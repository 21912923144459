<template>
  <v-card width="100%" height="100%" flat>
    <v-row>
      <v-col offset-md="3" md="6" cols="12">
        <v-card flat outlined width="100%" class="rounded-xl">
          <v-toolbar dark flat color="primary">
            <v-toolbar-title>
              <v-icon>mdi-hospital-box</v-icon>
              Select a Practice
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list rounded>
              <v-list-item
                class="my-5"
                v-for="(client, index) in clients"
                :key="index"
                @click="selectClient(client)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-hospital</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ client.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PracticeSelector",
  computed: {
    ...mapGetters(["clients", "currentClient"]),
  },
  methods: {
    ...mapActions(["setCurrentClient"]),
    selectClient(client) {
      this.setCurrentClient(client);
      this.$cookies.set("osteonCurrentClientUID", client.uid);
      this.$router.push({ name: "homePage" });
    },
  },
};
</script>
