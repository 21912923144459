<template>
  <v-row class="py-2">
    <v-col
      cols="12"
      :md="!standAlone ? 6 : 12"
      :offset-md="columnOffset"
      :class="colClass"
    >
      <v-card elevation="0" class="rounded-xl">
        <v-card-text class="py-0 my-0">
          <v-chip
            :id="chatHighlightUID === approval.uid ? 'messagePointer' : ''"
            color="teal"
            :class="chipClass"
            :style="chipStyle"
            :outlined="status !== 'Pending'"
            dark
          >
            <span style="white-space: pre-wrap">
              <h3 class="my-3" v-if="!standAlone">
                <span v-if="dentate === 'UPPER'">
                  <v-icon>mdi-arrow-up</v-icon>
                  Upper Dentate
                </span>
                <span v-if="dentate === 'LOWER'">
                  <v-icon>mdi-arrow-down</v-icon>
                  Lower Dentate
                </span>
                <span v-if="dentate === 'BOTH'">
                  <v-icon>mdi-arrow-up-down</v-icon>
                  Upper &amp; Lower Dentates
                </span>
                <span v-if="dentate === 'NONE'">
                  <v-icon>mdi-circle-outline</v-icon>
                  Other
                </span>
                <span v-if="kase.product_class !== 'Other'">
                  - {{ kase.product_class }}
                </span>
                <span v-if="kase.product_class === 'Other'">
                  - {{ kase.job_type }}
                </span>
              </h3>
              <p>
                {{ toTitleCase(approval.approval_type) }}
              </p>
              <p>
                <strong>
                  <i>Requested {{ approval.created_at | luxonRelative }}</i>
                </strong>
              </p>
              <p>
                Approval Due:
                <br />
                <v-icon class="mr-2">mdi-alarm</v-icon>
                <i>{{
                  approval.approval_due_at | luxon({ output: "tz_med" })
                }}</i>
              </p>
              <p>
                Status:
                <br />
                <v-icon v-if="status === 'Approved'" class="mr-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="status === 'Pending'" class="mr-2"
                  >mdi-account-clock</v-icon
                >
                <i>{{ status }}</i>
              </p>
              <span v-if="status === 'Approved'">
                <p>
                  Approved At:
                  <br />
                  <v-icon class="mr-2">mdi-clock</v-icon>
                  <i>{{
                    approval.approved_at | luxon({ output: "tz_med" })
                  }}</i>
                </p>
              </span>
            </span>
          </v-chip>
        </v-card-text>
        <v-card-text v-if="approval.approval_files.length > 0 && !standAlone">
          <ApprovalFiles :approval="approval" />
        </v-card-text>
        <v-card-text
          class="pt-5 mt-0 px-5"
          v-if="status === 'Pending' && standAlone"
        >
          <v-btn
            width="100%"
            outlined
            color="warning"
            class="rounded-xl"
            @click="openApproval()"
          >
            <v-icon small class="mr-2">mdi-flash</v-icon>
            Click Here to Action
          </v-btn>
        </v-card-text>
        <v-card-text
          class="pt-5 mt-0 px-5"
          v-if="status === 'Pending' && !standAlone"
        >
          <v-btn
            small
            outlined
            color="success"
            class="mr-2 mb-2 rounded-xl"
            @click="action = 'approve'"
          >
            <v-icon small class="mr-2">mdi-thumb-up</v-icon>
            Approve
          </v-btn>
          <v-btn
            small
            outlined
            color="warning"
            class="mr-2 mb-2 rounded-xl"
            @click="action = 'approve_with_changes'"
          >
            <v-icon small class="mr-2">mdi-thumb-up-outline</v-icon>
            Approve with Changes
          </v-btn>
          <v-btn
            small
            outlined
            color="error"
            class="mr-2 mb-2 rounded-xl"
            @click="action = 'request_changes'"
          >
            <v-icon small class="mr-2">mdi-swap-horizontal</v-icon>
            Request Changes
          </v-btn>
          <div class="text-center">
            <v-dialog
              v-model="actionDialog"
              max-width="800"
              content-class="rounded-xl"
            >
              <v-card flat>
                <v-toolbar dark flat class="mb-5" color="primary">
                  <span v-if="action === 'approve'">Approval</span>
                  <span v-if="action === 'approve_with_changes'"
                    >Approve with Changes</span
                  >
                  <span v-if="action === 'request_changes'"
                    >Request Changes</span
                  >
                </v-toolbar>
                <v-card-text v-if="loading" class="text-center">
                  <v-progress-circular indeterminate size="128" class="my-5">
                    Please Wait
                  </v-progress-circular>
                </v-card-text>
                <v-card-text v-if="warrantyVoid">
                  <v-alert color="error" class="my-5">
                    <p>Dear Valued Client,</p>
                    <p>
                      You are receiving this notification because the attached
                      design is outside of Osteon's design envelope. Examples
                      could include but are not limited to:
                    </p>
                    <div class="mb-5">
                      <ul>
                        <li>Below the minimum thickness</li>
                        <li>
                          The distal cantilever exceeds the acceptable length
                        </li>
                        <li>
                          Span between implants exceeds the acceptable length
                        </li>
                      </ul>
                    </div>
                    <h3 class="mb-5">
                      <v-icon class="mr-2">mdi-alert</v-icon>
                      Approval of this design as it is will result in a voided
                      warranty.
                    </h3>
                    <p>
                      Alternatively you can suggest appropriate changes to
                      ensure the design conforms to our approved dimensions.
                    </p>
                  </v-alert>
                </v-card-text>
                <v-card-text v-if="!loading && action === 'approve'">
                  <p>
                    By clicking Approve, you agree to proceed to the
                    manufacturing phase of this case using the design files
                    shared with you via this portal, and understand that
                    additional changes cannot be made.
                  </p>
                  <div v-if="approvalMessages.length > 0">
                    <p>
                      In addition, please ensure you've read the messages
                      pertaining to this proposal below:
                    </p>
                    <v-card class="rounded-xl mb-5" elevation="0" width="100%">
                      <CaseMessage
                        v-for="(message, index) in approvalMessages"
                        :key="index"
                        :message="message"
                      />
                    </v-card>
                  </div>
                  <p>Are you sure you wish to proceed?</p>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        color="success"
                        class="ma-1 rounded-xl"
                        @click="triggerAction()"
                      >
                        <v-icon class="mr-2">mdi-thumb-up</v-icon>
                        Approve
                      </v-btn>
                      <v-btn
                        color="error"
                        class="ma-1 rounded-xl"
                        @click="actionDialog = false"
                      >
                        <v-icon class="mr-2">mdi-cancel</v-icon>
                        Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text
                  v-if="!loading && action === 'approve_with_changes'"
                >
                  <p>
                    Please provide us with the details of the changes you'd like
                    made:
                  </p>
                  <p>
                    <v-form ref="changesForm" lazy-validation>
                      <v-textarea
                        :rules="[validateChanges]"
                        v-model="changes"
                        outlined
                        class="rounded-xl"
                        label="Details of Changes"
                      />
                      <v-file-input
                        v-model="attachments"
                        hide-details
                        multiple
                        outlined
                        prepend-icon=""
                        prepend-inner-icon="mdi-paperclip"
                        class="rounded-xl"
                        label="Attachments"
                      />
                    </v-form>
                  </p>
                  <div v-if="approvalMessages.length > 0">
                    <p>
                      In addition, please ensure you've read the messages
                      pertaining to this proposal below:
                    </p>
                    <v-card class="mb-5 rounded-xl" elevation="0" width="100%">
                      <CaseMessage
                        v-for="(message, index) in approvalMessages"
                        :key="index"
                        :message="message"
                      />
                    </v-card>
                  </div>
                  <p>
                    By clicking Accept with Changes, you agree to proceed to the
                    manufacturing phase of this case once your requested changes
                    have been made.
                  </p>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        color="success"
                        class="ma-1 rounded-xl"
                        @click="triggerAction()"
                      >
                        <v-icon class="mr-2">mdi-thumb-up-outline</v-icon>
                        Approve with Changes
                      </v-btn>
                      <v-btn
                        color="error"
                        class="ma-1 rounded-xl"
                        @click="actionDialog = false"
                      >
                        <v-icon class="mr-2">mdi-cancel</v-icon>
                        Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-if="!loading && action === 'request_changes'">
                  <p>
                    Please provide us with the details of the changes you'd like
                    made:
                  </p>
                  <p>
                    <v-form ref="changesForm" lazy-validation>
                      <v-textarea
                        :rules="[validateChanges]"
                        v-model="changes"
                        outlined
                        class="rounded-xl"
                        label="Details of Changes"
                      />
                      <v-file-input
                        v-model="attachments"
                        multiple
                        outlined
                        prepend-icon=""
                        prepend-inner-icon="mdi-paperclip"
                        class="rounded-xl"
                        label="Attachments"
                      />
                    </v-form>
                  </p>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        color="success"
                        class="ma-1 rounded-xl"
                        @click="triggerAction()"
                      >
                        <v-icon class="mr-2">mdi-swap-horizontal</v-icon>
                        Request Changes
                      </v-btn>
                      <v-btn
                        color="error"
                        class="ma-1 rounded-xl"
                        @click="actionDialog = false"
                      >
                        <v-icon class="mr-2">mdi-cancel</v-icon>
                        Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </v-card-text>
      </v-card>
      <div class="mx-5 mt-2" v-if="!standAlone">
        <small>
          {{ approval.created_at | luxon({ format: { output: "med" } }) }}
        </small>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApprovalFiles from "@/components/shared/ApprovalFiles";
import CaseMessage from "@/components/shared/CaseMessage";

export default {
  name: "ApprovalBubble",
  props: ["approval", "standAlone", "leftAlign"],
  components: {
    ApprovalFiles,
    CaseMessage,
  },
  data() {
    return {
      loading: false,
      action: null,
      changes: "",
      attachments: [],
    };
  },
  computed: {
    ...mapGetters(["collection", "casesByDentate", "chatHighlightUID"]),
    actionDialog: {
      get() {
        return !!this.action;
      },
      set(value) {
        if (value) {
          return;
        }
        this.action = null;
        this.changes = "";
        this.attachments = [];
      },
    },
    approvalMessages() {
      return this.collection.messages.filter(
        (message) => message.approval_uid === this.approval.uid
      );
    },
    validateChanges() {
      if (this.changes.length === 0) {
        return "Cannot be blank";
      }
      return true;
    },
    status() {
      if (this.approval.cancelled_at) {
        return "Cancelled";
      }
      if (this.approval.approved_at) {
        return "Approved";
      }
      return "Pending";
    },
    kase() {
      return this.collection.cases.find(
        (kase) => kase.uid === this.approval.case_uid
      );
    },
    warrantyVoid() {
      if (!this.kase) {
        return;
      }
      return this.kase.warranty_void;
    },
    dentate() {
      if (!this.casesByDentate) {
        return "NONE";
      }
      if (this.casesByDentate.upper.includes(this.kase)) {
        return "UPPER";
      }
      if (this.casesByDentate.lower.includes(this.kase)) {
        return "LOWER";
      }
      if (this.casesByDentate.both.includes(this.kase)) {
        return "BOTH";
      }
      if (this.casesByDentate.none.includes(this.kase)) {
        return "NONE";
      }
      return "NONE";
    },
    colSize() {
      if (this.standAlone) {
        return 12;
      }
      if (this.leftAlign) {
        return 12;
      }
      return 6;
    },
    colClass() {
      if (this.standAlone) {
        return "";
      }
      if (this.leftAlign) {
        return "";
      }
      return "text-right";
    },
    columnOffset() {
      if (this.standAlone) {
        return "";
      }
      return 6;
    },
    chipStyle() {
      if (this.standAlone) {
        return "height: auto; min-width: 100%;";
      }
      return "height: auto; min-width: 60%;";
    },
    chipClass() {
      if (this.approval.uid === this.chatHighlightUID) {
        return "pa-2 text-left pulsing";
      }
      return "pa-2 text-left";
    },
  },
  methods: {
    ...mapActions(["triggerReload", "setChatHighlightUID"]),
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    },
    openApproval() {
      this.setChatHighlightUID(this.approval.uid);
      this.$router.push({ name: "caseChat" });
    },
    async triggerAction() {
      if (this.$refs.changesForm && !this.$refs.changesForm.validate()) {
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("action", this.action);
      formData.append("changes", this.changes);
      this.attachments.forEach((attachment) => {
        formData.append("files", attachment, attachment.name);
      });
      this.$axios
        .post(`/approval/${this.approval.uid}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.triggerReload();
          this.actionDialog = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.pulsing {
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 140, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
  }
}
</style>
